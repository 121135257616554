import React, { useState, useRef, useEffect, useCallback } from 'react';
import { withRouter } from 'react-router-dom';
import { connect as felaConnect } from 'react-fela';
import * as yup from 'yup';
import { withFormik, Field } from 'formik';
import compose from 'lodash/flowRight';
import {
  IconButton,
  Button,
  InputError,
  Loading,
  ReportModal,
  FunctionalTextArea,
  VerifyEmailModal,
  TestimonialModal,
  Icon,
  FileUploader,
  LoadingCircle,
} from '../../../../../common/components';
import theme from '../../../../../theme';
import isUnread from '../../../utils/is-unread';
import messageReportReasons from '../../../../../common/utils/messageReportReasons';
import { USER_MESSAGE } from '../../../../../common/constants/report-types';
import ConfirmationModal from './ConfirmationModal';
import Header from './Header';
import Message from './Message';
import UpgradeModal from './UpgradeModal';
import NoLongerAvailable from './NoLongerAvailable';

import { handleFileUpload } from '../../../../../common/utils/uploads/handleFileUpload';
import createFileKey from '../../../../../common/utils/uploads/createFileKey';
const MAX_MESSAGE_LENGTH = 3000;
function ConversationDisplay({
  isArchive,
  conversation,
  loading,
  currentUser,
  listingGroup,
  userVM,
  history,
  location,
  status,
  resetForm,
  handleSubmit,
  isMobile,
  goBack,
  values,
  isSubmitting,
  onArchive,
  onUnarchive,
  setStatus,
  getConversations,
  styles,
  getUploadToken,
  setFieldValue,
  deleteMessagePhoto,
}) {
  const [newFromId, setNewFromId] = useState(null);
  const [showReportModal, setShowReportModal] = useState(false);
  const [showTestimonialModal, setShowTestimonialModal] = useState(false);
  const [block, setBlock] = useState(false);
  const [showVerifyEmailModal, setShowVerifyEmailModal] = useState(false);
  const [confirmStatusChange, setConfirmStatusChange] = useState(null);
  const [loaderCount, setLoaderCount] = useState(0);

  const conversationWrapper = useRef();
  // old didMount
  useEffect(() => {
    scrollToBottom('smooth');
    setNewFromId();
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const scrollToBottom = useCallback((behavior = 'auto') => {
    const wrapperElement = conversationWrapper.current;
    if (wrapperElement) {
      if (wrapperElement.scrollTo) {
        wrapperElement.scrollTo({ top: wrapperElement.scrollHeight, behavior });
      } else {
        // IE11
        wrapperElement.scrollTop = wrapperElement.scrollHeight;
      }
    }
  }, []);

  // capture prev state
  const prevConversation = useRef(conversation);
  useEffect(() => {
    prevConversation.current = conversation;
  }, [conversation, status]);

  useEffect(() => {
    if (prevConversation.current !== conversation) {
      scrollToBottom('smooth');
      resetForm();
    }
  }, [prevConversation, conversation, resetForm, scrollToBottom]);

  useEffect(() => {
    if (conversation && (!prevConversation.current || prevConversation.current.get('id') !== conversation.get('id'))) {
      doSetNewFromId();
      scrollToBottom('smooth');
    }
    function doSetNewFromId() {
      const oldestUnread =
        conversation &&
        conversation
          .get('messages')
          .find(message =>
            isUnread(message, currentUser, listingGroup ? listingGroup.get('listing') : conversation.get('listing'))
          );
      setNewFromId(oldestUnread && oldestUnread.get('id'));
    }
  }, [conversation, prevConversation, currentUser, listingGroup, scrollToBottom]);

  useEffect(() => {
    if (status?.isUnverifiedEmail) {
      setShowVerifyEmailModal(true);
    }
  }, [status, setShowVerifyEmailModal]);

  function getListing() {
    return listingGroup ? listingGroup.get('listing') : conversation.get('listing');
  }

  function getOtherUser() {
    return conversation.get('users').find(user => user.get('id') !== currentUser.get('id'));
  }

  function isNoLongerAvailable() {
    const listing = getListing();

    return (
      listing &&
      listing.get('status') === 'sold' &&
      listing.get('seller') !== currentUser.get('id') &&
      listing.get('sold_to') !== currentUser.get('id')
    );
  }

  if (!conversation) {
    return null;
  }

  if (loading) {
    return <Loading dark center />;
  }
  const otherUser = getOtherUser();
  const listing = getListing();
  const isOwnListing = currentUser.get('id') === listing?.get('seller');
  const isSoldListing = listing?.get('status') === 'sold';
  const isBuyerConversation =
    isSoldListing &&
    (listing?.get('sold_to') === otherUser.get('id') || listing?.get('sold_to') === currentUser.get('id'));

  const handleUpload = async images => {
    setLoaderCount(images.length);
    const photos = images.map(async image => {
      const photo = await handleFileUpload({
        getUploadToken,
        file: image.data,
        key: createFileKey(),
      });
      return { key: photo.Key, data: image.data };
    });
    const res = await Promise.all(photos);
    const formPhotos = [...values.photos, ...res];
    setFieldValue('photos', formPhotos);
    setLoaderCount(0);
  };

  const removeImage = index => {
    values.photos.splice(index, 1);
    const newPhotos = [...values.photos];
    setFieldValue('photos', newPhotos);
  };

  const renderLoaders = () => {
    let loaders = [];
    for (let i = 0; i < loaderCount; i++) {
      loaders.push(
        <div className={styles.loading}>
          <LoadingCircle />
        </div>
      );
    }
    return loaders;
  };

  const isBlocked =
    currentUser && currentUser.get('blockedUserIds')
      ? currentUser.get('blockedUserIds').findIndex(blockedUserId => blockedUserId === otherUser.get('id')) !== -1
      : false;

  return (
    <div className={styles.conversationDisplay}>
      <div className={styles.conversationTop}>
        <Header
          isArchive={isArchive}
          styles={styles}
          listingGroup={listingGroup}
          currentUser={currentUser}
          isMobile={isMobile}
          goBack={goBack}
          userVM={userVM}
          otherUser={otherUser}
          listing={getListing()}
          location={location}
        />
        <div ref={conversationWrapper} className={styles.conversationWrapper}>
          {conversation.get('messages').map(message => (
            <Message
              key={message.get('id')}
              message={message}
              currentUser={currentUser}
              styles={styles}
              newFromId={newFromId}
              listing={getListing()}
              showTestimonialModal={() => setShowTestimonialModal(true)}
              conversation={conversation}
              history={history}
              deleteMessagePhoto={deleteMessagePhoto}
            />
          ))}
          {isSoldListing && !isBuyerConversation ? (
            <NoLongerAvailable styles={styles} isSeller={isOwnListing} listingName={listing?.get('name')} />
          ) : null}
        </div>
      </div>
      {isArchive ? (
        <div className={styles.archivedButtonRow}>
          <Button
            buttonType="round"
            type="button"
            disabled={isBlocked}
            onClick={() => setConfirmStatusChange('unarchive')}
          >
            Unarchive
          </Button>
          {isBlocked ? <div className={styles.redText}>This conversation has conluded.</div> : null}
        </div>
      ) : (
        <div className={styles.formWrapper}>
          {isBlocked ? null : (
            <form
              id="send-message-form"
              onSubmit={handleSubmit}
              className={styles.form}
              data-testid="send-message-form"
            >
              <div>
                {listing && listing.get('seller') === currentUser.get('id') ? (
                  values.photos.length < 8 ? (
                    <FileUploader
                      name="images"
                      allowMultiple
                      limit={8 - values.photos.length}
                      innerText=""
                      variant="blank"
                      noStyles={true}
                      onFilesLoaded={images => {
                        handleUpload(images);
                      }}
                      accept={{
                        'image/png': ['.png'],
                        'image/jpeg': ['.jpeg', '.jpg'],
                      }}
                    >
                      <div className={styles.imageUpload}>
                        <Icon icon="Images" size={22} />
                      </div>
                    </FileUploader>
                  ) : null
                ) : null}
              </div>
              <div className={styles.textAreaWrapper}>
                <Field
                  label=""
                  aria-label="Message Body"
                  name="body"
                  id="body"
                  component={FunctionalTextArea}
                  height={40}
                  value={values.body}
                  className={styles.messageBody}
                  disabled={isNoLongerAvailable()}
                />

                <div className={values.photos.length > 0 ? styles.imageContainer : ''}>
                  {values.photos.map((file, idx) => {
                    return (
                      <div className={styles.imageWrapper} key={idx}>
                        <span className={styles.removeImage} onClick={() => removeImage(idx)}>
                          <Icon icon="PhotoDelete" size={12} />
                        </span>
                        <img src={file.data} alt="" />
                      </div>
                    );
                  })}
                  {renderLoaders()}
                  {values.photos.length > 0 ? (
                    <span className={styles.photoWarningText}>Photos cannot be deleted once sent</span>
                  ) : null}
                </div>
              </div>
              {}
              <button
                className={styles.sendButton}
                type="submit"
                disabled={
                  isSubmitting ||
                  (values.body.trim() === '' && values.photos.length === 0) ||
                  isNoLongerAvailable() ||
                  !otherUser
                }
              >
                <Icon icon="PaperAirplane" size={22} />
              </button>
            </form>
          )}
          {status && status.error ? <InputError fixederror>{status.error}</InputError> : null}
          <div className={styles.buttonRow}>
            <IconButton title="Archive" icon="Archive" onClick={() => setConfirmStatusChange('archive')} />
            <IconButton
              onClick={() => setShowReportModal(true)}
              title="Report"
              icon="Warning"
              data-testid="report-button"
              variant={
                !getOtherUser() ||
                !conversation.get('messages').find(message => message.get('author_id') !== currentUser.get('id'))
                  ? 'disabled'
                  : 'red'
              }
              disabled={
                !getOtherUser() ||
                !conversation.get('messages').find(message => message.get('author_id') !== currentUser.get('id'))
              }
            />
            {isBlocked ? (
              <div className={styles.redText}>This conversation has conluded</div>
            ) : (
              <IconButton
                onClick={() => {
                  setShowReportModal(true);
                  setBlock(true);
                }}
                title="Block"
                icon="Block"
                variant={!getOtherUser() ? 'disabled' : 'red'}
                disabled={isBlocked || !getOtherUser()}
              />
            )}
            <a
              href="https://help.puppies.com/article/129-buyer-tips"
              target="_blank"
              rel="noopener noreferrer"
              className={styles.link}
            >
              Buyer Tips
            </a>
          </div>
        </div>
      )}
      <ConfirmationModal
        conversation={conversation}
        onArchive={onArchive}
        onUnarchive={onUnarchive}
        confirmStatusChange={confirmStatusChange}
        setConfirmStatusChange={() => setConfirmStatusChange(null)}
      />
      <UpgradeModal history={history} status={status} setStatus={setStatus} userVM={userVM} />
      {getListing() ? (
        <TestimonialModal
          isOpen={showTestimonialModal}
          onClose={() => setShowTestimonialModal(false)}
          listing={getListing()}
          seller={getOtherUser()}
        />
      ) : null}
      {getOtherUser() ? (
        <ReportModal
          isOpen={showReportModal}
          onClose={() => {
            getConversations();
            setShowReportModal(false);
            setBlock(false);
          }}
          block={block}
          user={otherUser}
          reasons={messageReportReasons}
          type={USER_MESSAGE}
          entity_id={conversation.get('id')}
          buttonText={'Report User'}
          title={`${block ? `Block` : `Report`} ${otherUser.get('first_name')} ${otherUser.get('last_initial')}?`}
          photo={otherUser.get('photo')}
        />
      ) : null}
      <VerifyEmailModal
        user={currentUser}
        isOpen={showVerifyEmailModal}
        onClose={() => setShowVerifyEmailModal(false)}
      />
    </div>
  );
}

const MessagesForm = withFormik({
  mapPropsToValues: props => ({ body: '', photos: [] }),
  validationSchema: yup.object().shape({
    body: yup
      .string()
      .trim()
      .max(MAX_MESSAGE_LENGTH, `Must be less than ${MAX_MESSAGE_LENGTH} characters`),
    photos: yup.array(),
  }),
  handleSubmit: (values, { props, resetForm, setStatus, setSubmitting }) => {
    let filterPhotos = [];
    if (values.photos) {
      for (let index = 0; index < values.photos.length; index++) {
        const key = values.photos[index].key;
        filterPhotos.push({ key });
      }
    }
    setStatus({});
    props
      .replyToConversation(
        props.conversation.get('id'),
        values.body === '' ? 'NO_CONTENT' : values.body,
        props.listingGroup && props.listingGroup.get('listing_id'),
        filterPhotos
      )
      .then(action => {
        if (action?.response.ok) {
          resetForm();
          setStatus({ success: true });
        } else {
          if (action?.json?.message.includes('Expired membership')) {
            setStatus({ error: action?.json.message, showUpgradeModal: true });
          } else {
            setStatus({
              error: action?.json ? action?.json.message : 'Could not send message at this time',
              isUnverifiedEmail: !!action?.json.isUnverifiedEmail,
            });
          }
          setSubmitting(false);
        }
      });
  },
});

const styles = props => ({
  idVerified: {
    backgroundColor: '#74CF3C',
    color: '#fff',
    fontSize: '12px',
    padding: '2px 10px',
    borderRadius: '4px',
    marginLeft: '8px',
    position: props.isMobile ? 'inherit' : 'absolute',
    top: props.isMobile ? 'unset' : '0',
  },
  idNotVerified: {
    backgroundColor: '#727272',
    color: '#fff',
    fontSize: '12px',
    padding: '2px 10px',
    borderRadius: '4px',
    marginLeft: '8px',
    position: props.isMobile ? 'inherit' : 'absolute',
    top: props.isMobile ? 'unset' : '0',
  },
  conversationDisplay: {
    flex: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    borderRight: `1px solid ${props.theme.colors.darkerTan}`,
    [props.theme.breakpoints.tablet]: {
      justifyContent: 'flex-start',
      height: 'auto',
    },
  },
  mobileConversationDisplayHeaderContainer: {
    position: 'fixed',
    top: '82px',
    zIndex: 1,
  },
  conversationDisplayHeaderContainer: {
    zIndex: 1,
    width: '100%',
    padding: '20px 0',
    backgroundColor: props.theme.colors.tan,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    color: props.theme.colors.darkerGray,
    '> * + *': {
      marginTop: '20px',
    },
  },
  conversationDisplayHeaderWrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: props.isMobile ? 'space-between' : 'flex-start',
    padding: '0 10px',
    width: '100%',
    position: 'relative',
    [props.theme.breakpoints.mobile]: {
      padding: '0 40px',
    },
  },
  conversationDisplayHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: props.isMobile ? 'initial' : '100%',
    maxWidth: props.isMobile ? 'initial' : '679px',
  },
  conversationDisplayHeaderTitle: {
    maxWidth: '80%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  conversationInfo: {
    display: 'flex',
    alignItems: 'center',
  },
  conversationTop: {
    height: 'auto',
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    [props.theme.breakpoints.tablet]: {
      flex: 1,
    },
  },
  conversationWrapper: {
    marginTop: '80px',
    marginBottom: '200px',
    padding: '30px 20px 0',
    color: props.theme.colors.brown,
    overflowY: 'auto',
    WebkitOverflowScrolling: 'touch',
    [props.theme.breakpoints.mobile]: {
      padding: '30px 40px 0',
    },
    [props.theme.breakpoints.tablet]: {
      width: '100%',
      height: '100%',
      marginTop: 0,
      marginBottom: 0,
    },
    '> *': {
      marginBottom: '16px',
    },
  },
  messageWrapper: {
    fontSize: '14px',
    lineHeight: '20px',
    maxWidth: props.isMobile ? 'initial' : '679px',
  },
  messageWrapperNew: {
    paddingTop: 0,
    borderTop: 'none',
  },
  messageWrapperExpired: {
    color: '#D0021B',
  },
  innerMessageWrapper: {
    display: 'flex',
    flexDirection: 'column',
    '> * + *': {
      marginTop: '24px',
    },
    [props.theme.breakpoints.mobile]: {
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      '> * + *': {
        marginTop: 0,
        marginLeft: '50px',
      },
    },
  },
  yourMessage: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
  messageBody: {
    fontSize: '14px',
    color: '#000',
  },
  redText: {
    color: props.theme.colors.red,
  },
  newMessageBorder: {
    textAlign: 'center',
    position: 'relative',
    paddingBottom: '16px',
    fontSize: '11px',
    textTransform: 'uppercase',
    color: props.theme.colors.red,
    ':before': {
      content: '""',
      position: 'absolute',
      top: '10px',
      left: 0,
      height: '1px',
      width: '47%',
      backgroundColor: props.theme.colors.red,
    },
    ':after': {
      content: '""',
      position: 'absolute',
      top: '10px',
      right: 0,
      height: '1px',
      width: '47%',
      backgroundColor: props.theme.colors.red,
    },
  },
  author: {
    fontWeight: props.theme.typography.sansBold,
    maxWidth: '100%',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    marginRight: '4px',
  },
  message: {
    backgroundColor: '#efefef',
    color: props.theme.colors.textDark,
    width: 'fit-content',
    padding: '10px 18px',
    marginTop: '10px',
    borderRadius: '18px',
    wordBreak: 'break-word',
  },
  yourSentMessage: {
    backgroundColor: `${props.theme.colors.blue} !important`,
    color: `${props.theme.colors.white} !important`,
  },
  timestamp: {
    fontSize: '11px',
    color: '#9E9E9E',
    margin: '4px 4px 0 0',
  },
  formWrapper: {
    backgroundColor: props.theme.colors.tan,
    padding: '20px 16px 24px',
    '@media (max-width: 864px)': {
      position: 'fixed',
      width: '100%',
      bottom: 0,
    },
  },
  photoWarningText: {
    color: props.theme.colors.blue,
    fontSize: '12px',
    position: 'absolute',
    bottom: '67px',
  },
  form: {
    display: 'flex',
    gap: '1em',
    alignItems: 'end',

    '& > div > div > textarea': {
      width: '100%',
      maxWidth: 'unset',
      border: 'none',
    },
  },
  archivedButtonRow: {
    borderTop: `1px solid ${props.theme.colors.darkerTan}`,
    marginTop: '20px',
    padding: '20px',
    display: 'flex',
    fontSize: '12px',
    alignItems: 'center',
    // flexDirection: 'column',
    flexDirection: 'row',
    justifyContent: 'space-between',

    '> button': {
      width: '100%',
      [props.theme.breakpoints.tablet]: {
        marginBottom: '20px',
        width: 'inherit',
        margin: 0,
      },
    },
  },
  buttonRow: {
    marginTop: '20px',
    display: 'flex',
    fontSize: '12px',
    alignItems: 'center',
    // flexDirection: 'column',
    flexDirection: 'row',
    justifyContent: 'space-between',

    '> button': {
      width: '100%',
      [props.theme.breakpoints.tablet]: {
        marginBottom: '20px',
        width: 'inherit',
        margin: 0,
      },
    },
  },
  avatars: {
    display: 'flex',
    alignItems: 'center',
    '> * + *': {
      marginLeft: '10px',
    },
  },
  price: {
    color: props.theme.colors.green,
    fontWeight: props.theme.typography.sansBold,
    marginLeft: '4px',
  },
  conversation: {
    marginLeft: '14px',
    lineHeight: '20px',
    textAlign: 'left',
    '@media (max-width: 400px)': {
      fontSize: '14px',
      marginLeft: '0',
    },
  },
  listingStatusFormWrapper: {
    width: 'auto',
    [props.theme.breakpoints.mobile]: {
      width: '128px',
    },
  },
  goBackButton: {
    color: props.theme.colors.blue,
  },
  listingStatusFormBottom: {
    marginBottom: '20px',
  },
  link: {
    color: props.theme.colors.blue,

    fontWeight: props.theme.typography.sansBold,
    textDecoration: 'underline',
    whiteSpace: 'nowrap',
  },
  automatedReply: {
    color: props.theme.colors.orange,
  },
  systemMessageText: {
    fontFamily: theme.typography.sans,
    fontWeight: theme.typography.sansRegular,
    fontSize: '14px',
    lineHeight: '1.2',
    color: '#1AB9C4',
    backgroundColor: '#E9F4E1',
    padding: '10px 18px',
    borderRadius: theme.globalBorderRadius,

    '> * a': {
      textDecoration: 'underline',
    },
  },
  imageUpload: {
    height: '35px',
    width: '35px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: props.theme.colors.blue,
    margin: 'auto',
    borderRadius: '50%',
    cursor: 'pointer',
  },
  sendButton: {
    height: '35px',
    width: '35px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: props.theme.colors.blue,
    padding: '10px',
    borderRadius: '50%',
  },
  textAreaWrapper: {
    flexGrow: 1,
    backgroundColor: 'white',
    border: `1px solid #C2C2C2`,
    borderRadius: '10px',
    overflow: 'hidden',
    padding: '5px',
  },
  imageContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginBottom: '1em',
    paddingTop: '10px',
  },
  imageWrapper: {
    position: 'relative',
    width: '80px',
    height: '80px',
    margin: '6px',
    '> img': {
      objectFit: 'cover',
      width: '100%',
      maxHeight: '100%',
      maxWidth: '100%',
      borderRadius: '10px',
    },
  },
  removeImage: {
    position: 'absolute',
    top: '-12px',
    right: '-12px',
    cursor: 'pointer',
    padding: '6px',
    borderRadius: '50%',
    backgroundColor: props.theme.colors.white,
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.24)',
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '80px',
    height: '80px',
    boxShadow: '0px 1px 3px 0px rgba(0, 0, 0, 0.24)',
  },
  messagePhotosContainer: {
    marginTop: '5px',
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    gap: '1em',
  },
  messagePhotoContainer: {
    cursor: 'pointer',
    position: 'relative',
    '> img': {
      borderRadius: '12px',
      width: '100px',
      height: '100px',
      objectFit: 'contain',
    },
  },
});

export default compose(MessagesForm, withRouter, felaConnect(styles))(ConversationDisplay);
