const styles = props => ({
  cancelModalBody: {
    padding: '64px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    color: '#D0021B',
    '>h1': {
      marginTop: '10px',
      fontSize: '28px',
      lineHeight: '38px',
      fontWeight: props.theme.typography.sansBold,
    },
    '>p': {
      marginTop: '16px',
      color: props.theme.colors.brown,
      width: '100%',
    },
  },
  container: {
    backgroundColor: props.theme.colors.tan,
    color: props.theme.colors.brown,
    paddingBottom: '32px',
  },
  wrapper: {
    ...props.theme.globalPageWrapper,
  },
  title: {
    fontFamily: props.theme.typography.sansAlt,
    fontWeight: props.theme.typography.sansAltMedium,
    color: props.theme.colors.orange,
    whiteSpace: 'nowrap',
    fontSize: '28px',
  },
  titleWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '60px 20px 40px',
  },
  titleSeparator: {
    width: '64px',
    height: '5px',
    background: props.theme.colors.yellow,
    margin: '16px 0',
  },
  sellerMembershipIntro: {
    fontSize: '18px',
    lineHeight: '24px',
    fontWeight: props.theme.typography.sansRegular,
    textAlign: 'center',
  },
  membershipLevel: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '24px',
    background: 'transparent',
    border: `3px solid ${props.theme.colors.blue}`,
    borderRadius: '4px',
    textAlign: 'left',
    '> * + *': {
      marginTop: '20px',
    },
    [props.theme.breakpoints.tablet]: {
      flexDirection: 'row',
      alignItems: 'center',
      padding: '30px',
      borderRadius: '4px',
      '> * + *': {
        marginTop: 0,
      },
    },
  },
  premierLevel: {
    backgroundColor: props.theme.colors.premierBlue,
    color: props.theme.colors.white,
    border: `1px solid ${props.theme.colors.premierBlue}`,
  },
  membershipLevelText: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    fontSize: '24px',
    lineHeight: '36px',
    margin: 0,
    '> p': {
      '> strong': {
        display: 'inline-block',
        fontWeight: props.theme.typography.sansBold,
      },
    },
    [props.theme.breakpoints.tablet]: {
      marginRight: '40px',
    },
  },
  membershipLevelActionsContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'center',

    '> button': {
      width: '100%',
      margin: '8px 0',
    },
    '> a': {
      margin: '8px 0',

      ':first-of-type': {
        color: props.theme.colors.blue,
      },
      ':last-of-type': {
        color: props.theme.colors.red,
      },
    },
    [props.theme.breakpoints.tablet]: {
      flexDirection: 'row',
      '> button': {
        width: 'auto',
        margin: '0 8px',
      },
      '> a': {
        margin: '0 16px',
      },
    },
  },
  membershipExpired: {
    color: props.theme.colors.orange,
  },
  listingStatusParagraph: {
    fontFamily: props.theme.typography.sans,
    fontWeight: props.theme.typography.sansBold,
    fontSize: '16px',
    lineHeight: '21px',
    color: props.theme.colors.textDark,
    marginTop: '5px',
  },
  membershipCards: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: props.theme.globalMaxWidth,
    margin: '24px auto 0',
    [props.theme.breakpoints.tablet]: {
      padding: '40px 20px 0',
      borderRadius: '4px',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'stretch',
      margin: '0 auto',
    },
  },
  membershipCardWrapper: {
    maxWidth: props.theme.globalMaxWidth,
    margin: '0 auto',
    padding: '0 24px',
    [props.theme.breakpoints.tablet]: {
      ...props.theme.globalPadding,
    },
    '> * + *': {
      marginTop: '30px',
    },
  },
  expiredMembershipWrapper: {
    backgroundColor: 'transparent !important',
    borderWidth: '3px',
  },
  savingsChip: {
    backgroundColor: props.theme.colors.orange,
    color: 'white',
    fontWeight: props.theme.typography.sansBold,
    borderRadius: '2px',
    display: 'inline-block',
    fontSize: '14px',
    textAlign: 'center',
    padding: '5px 12px',
    marginBottom: '16px',
  },
  premierCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'stretch',
    backgroundColor: props.theme.colors.white,
    textAlign: 'center',
    fontSize: '14px',
    margin: '0 12px 24px',
    [props.theme.breakpoints.tablet]: {
      flex: '1',
      flexDirection: 'column',
      margin: '0 24px',
    },
    ':first-of-type': {
      marginLeft: '12px',
      [props.theme.breakpoints.tablet]: {
        marginLeft: 0,
      },
    },
    ':last-of-type': {
      marginRight: '12px',
      [props.theme.breakpoints.tablet]: {
        marginRight: 0,
      },
    },
  },
  membershipCardPayment: {
    textAlign: 'center',
    marginTop: '4px',
    '>h4': {
      fontSize: '36px',
      fontWeight: props.theme.typography.sansBold,
    },
  },
  premierCardLeft: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    padding: '42px 24px 32px',
    position: 'relative',
    textAlign: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundColor: props.theme.colors.white,
    color: props.theme.colors.textDark,
    borderTop: `18px solid ${props.theme.colors.premierBlue}`,

    '> h1': {
      width: '100%',
      marginTop: '10px',
      fontWeight: props.theme.typography.sansBold,
      fontSize: '16px',
      lineHeight: '1.25',
    },
  },
  premierPaymentDesc: {
    '> h4': {
      fontSize: '18px',
      fontWeight: props.theme.typography.sansBold,
      marginBottom: '8px',
    },
    '> div': {
      fontWeight: props.theme.typography.sansBold,
    },
  },
  premierCardRight: {
    height: '100%',
    width: '100%',
    borderLeft: '0',
    borderBottomLeftRadius: '0',
    borderBottomRightRadius: '0',
    '> h3': {
      fontWeight: props.theme.typography.sansBold,
      fontSize: '14px',
      lineHeight: '1.25',
    },
    '> ul': {
      marginTop: '20px',
      '> li': {
        fontWeight: props.theme.typography.sansRegular,
        lineHeight: '32px',
        marginLeft: '18px',
      },
    },
    [props.theme.breakpoints.tablet]: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      textAlign: 'left',
      borderBottomLeftRadius: '2px',
      borderBottomRightRadius: '2px',
      '> ul': {
        listStyle: 'disc',
      },
    },
    '@media (max-width: 540px)': {
      border: 'transparent',
    },
  },
  recurringText: {
    fontSize: '13px',
    marginTop: '6px',
  },
  planActionContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '24px',
    backgroundColor: props.theme.colors.darkerTan,
    '> h4': {
      fontSize: '16px',
      fontWeight: 'bold',
      marginBottom: '4px',
    },
    '> p': {
      marginBottom: '20px',
    },
  },
  premierPlans: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    marginTop: '16px',
    '> button:first-child': {
      borderTopRightRadius: '0px',
      borderBottomRightRadius: '0px',
    },
    '> button:last-child': {
      borderTopLeftRadius: '0px',
      borderBottomLeftRadius: '0px',
    },
    '@media (max-width: 864px)': {
      justifyContent: 'center',
    },
  },
  planPerksList: {
    display: 'flex',
    flexDirection: 'column',
    lineHeight: '1.5',
    margin: '12px 0 12px 24px',
    listStyleImage: `url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='12px' height='12px' viewBox='0 0 12 12' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3EShape%3C/title%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg fill='%231AB9C4' fill-rule='nonzero'%3E%3Cpath d='M12,6 C12,9.31372 9.31372,12 6,12 C2.68628,12 0,9.31372 0,6 C0,2.68628 2.68628,0 6,0 C9.31372,0 12,2.68628 12,6 Z M5.30598,9.17695 L9.7576,4.72534 C9.90876,4.57418 9.90876,4.32907 9.7576,4.17791 L9.21017,3.63048 C9.05901,3.4793 8.8139,3.4793 8.66272,3.63048 L5.03226,7.26092 L3.33728,5.56594 C3.18612,5.41478 2.94102,5.41478 2.78983,5.56594 L2.2424,6.11337 C2.09124,6.26453 2.09124,6.50964 2.2424,6.6608 L4.75853,9.17693 C4.90972,9.32811 5.1548,9.32811 5.30598,9.17695 L5.30598,9.17695 Z' %3E%3C/path%3E%3C/g%3E%3C/g%3E%3C/svg%3E") `,

    '> li': {
      display: 'list-item',
      margin: '8px 0',
      textAlign: 'left',
    },
  },
  planInfoContainer: {
    borderTop: `1px solid ${props.theme.colors.disabled}`,
    padding: '32px 0',
    margin: '0 20px',
    '> strong': {
      margin: '0 0 12px 24px',
    },
  },
  premierPlanInfoContainer: {
    border: 'none',
    paddingTop: 0,
    margin: '0 20px',

    '> h4': {
      fontWeight: props.theme.typography.sansBold,
      color: props.theme.colors.premierBlue,
      textAlign: 'center',
      marginBottom: '12px',
    },
  },
  membershipCardTop: {
    display: 'flex',
    flexDirection: 'row',
    [props.theme.breakpoints.mobile]: {
      flexDirection: 'column',
    },
  },
  memberShipCardPricing: {
    textAlign: 'left',
    [props.theme.breakpoints.mobile]: {
      textAlign: 'center',
    },
  },
  membershipCardTitle: {
    fontWeight: props.theme.typography.sansBold,
    fontSize: '20px',
    lineHeight: '25px',
    marginTop: 0,
    width: '100%',
    [props.theme.breakpoints.mobile]: {
      marginTop: '10px',
    },
  },
  membershipCardFrequency: {
    display: 'none',
    fontSize: '13px',
    marginTop: '3px',
    [props.theme.breakpoints.mobile]: {
      display: 'block',
    },
  },
  premierPuppyIcons: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: '24px',
    [props.theme.breakpoints.mobile]: {
      alignItems: 'flex-end',
      margin: 0,
    },
  },
  premierPuppyIcon: {
    top: '10px',
    [props.theme.breakpoints.mobile]: {
      top: '-2px',
    },
  },
  extendModalWrapper: {
    padding: '20px',
  },
  extendCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '20px',
    fontWeight: props.theme.typography.sansBold,
    color: props.theme.colors.white,
    textAlign: 'left',
    padding: '24px 16px',
    [props.theme.breakpoints.mobile]: {
      flexDirection: 'row',
    },
  },
  extendCardLeft: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100px',
    '> * + *': {
      marginTop: '4px',
    },
  },
  extendCardRight: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: props.theme.colors.white,
    fontSize: '20px',
    fontWeight: props.theme.typography.sansBold,
    marginTop: '20px',
    '> * + *': {
      marginTop: '10px',
    },
    [props.theme.breakpoints.mobile]: {
      flexDirection: 'row',
      marginTop: 0,
      '> * + *': {
        marginTop: 0,
        marginLeft: '16px',
      },
    },
  },
  extendHeader: {
    marginBottom: '24px',
    '>h1': {
      fontSize: '28px',
      fontWeight: props.theme.typography.sansBold,
    },
    '>p': { fontSize: '14px', marginTop: '10px' },
  },
  trialMembershipWrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '24px',
    backgroundColor: props.theme.colors.darkerTan,
    border: `none !important`,
    textAlign: 'left',
    '> * + *': {
      marginTop: '20px',
    },
    [props.theme.breakpoints.tablet]: {
      flexDirection: 'row',
      alignItems: 'center',
      padding: '30px',
      borderRadius: '4px',
      '> * + *': {
        marginTop: 0,
      },
    },
  },
  trialUpgradeText: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'flex-end',
    alignItems: 'flex-start',
    borderTop: `3px solid ${props.theme.colors.orange}`,
    borderLeft: 'none',
    padding: '20px 0 0 0',
    margin: '20px 0 0 0',

    '> strong': {
      marginBottom: '4px',
    },
    [props.theme.breakpoints.tablet]: {
      borderLeft: `3px solid ${props.theme.colors.orange}`,
      borderTop: 'none',
      padding: '0 0 0 20px',
      margin: '0 0 0 20px',
    },
  },
  trialExpiredText: {
    flex: 1,
    '> h4': {
      color: props.theme.colors.orange,
      fontSize: '24px',
      lineHeight: '36px',
    },
    '> p': {
      marginTop: '8px',
      fontWeight: props.theme.typography.sansBold,
    },
  },
  expiredMembership: {
    borderRadius: '4px',
    padding: '20px',
    backgroundColor: props.theme.colors.darkerTan,
    textAlign: 'center',
    '>h1': {
      fontSize: '35px',
      lineHeight: '47px',
      color: props.theme.colors.orange,
    },
    '>p': {
      marginTop: '10px',
      fontSize: '14px',
    },
  },
  trialMembershipBanner: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: '4px',
    padding: '40px 30px',
    backgroundColor: props.theme.colors.darkerTan,
    '>h1': {
      fontSize: '35px',
      lineHeight: '43px',
    },
    '>p': {
      lineHeight: '19px',
      marginTop: '30px',
      fontSize: '14px',
      flexBasis: '45%',
    },
    [props.theme.breakpoints.tablet]: {
      flexDirection: 'row',
      '>h1': {
        flexBasis: '55%',
      },
      '>p': {
        flexBasis: '45%',
        paddingLeft: '40px',
        marginTop: 0,
        marginLeft: '30px',
        borderLeft: `1px solid ${props.theme.colors.brown}`,
      },
    },
  },
  orange: {
    color: props.theme.colors.orange,
    whiteSpace: 'nowrap',
  },
  upgradeCard: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: '30px',
    padding: '30px',
    color: '#ffffff',
    backgroundColor: props.theme.colors.premierBlue,
    borderRadius: '3px',
    [props.theme.breakpoints.tablet]: {
      flexDirection: 'row',
      borderRadius: '4px',
    },
  },
  upgradeCardleft: {
    display: 'flex',
    flex: 2,
    flexDirection: 'column',
    textAlign: 'center',

    '> p': {
      fontSize: '16px',
      lineHeight: '25px',
    },
    [props.theme.breakpoints.tablet]: {
      textAlign: 'left',

      '> p': {
        fontSize: '14px',
        lineHeight: '22px',
      },
    },
  },
  upgradeCardTitle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    '> span': {
      display: 'none',
      marginRight: '4px',
    },
    '> h4': {
      fontSize: '24px',
      marginBottom: '8px',
    },
    [props.theme.breakpoints.tablet]: {
      flexDirection: 'row',
      alignItems: 'unset',
      textAlign: 'left',
      '> span': {
        display: 'flex',
      },
    },
  },
  whiteSpacer: {
    display: 'inline-block',
    width: '64px',
    height: '2px',
    background: props.theme.colors.white,
    margin: '16px 0',
    [props.theme.breakpoints.tablet]: {
      display: 'none',
    },
  },
  upgradeCardRight: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '32px',

    [props.theme.breakpoints.tablet]: {
      alignItems: 'flex-end',
      marginTop: 0,
    },
  },
  upgradeCardRightContent: {
    '> button': {
      marginTop: '10px',
    },
    [props.theme.breakpoints.tablet]: {
      marginTop: 0,
    },
  },
  upgradeCardPrice: {
    fontSize: '16px',
    fontWeight: props.theme.typography.sansSemi,
    textAlign: 'center',
  },
  paymentModal: {
    borderRadius: '6px !important',
  },
  premierPlanButtonPadding: {
    padding: '8px 4px !important',
    background: '#FFFFFF',
    boxShadow: '0 2px 1px 0 #e6e6e6 !important',
    color: '#0574D4',
    border: `1px solid ${props.theme.colors.disabled}`,
    '&:hover': {
      color: '#0574D4 !important',
      background: 'rgba(5,116,212,0.05)',
      border: `1px solid ${props.theme.colors.premierBlue}`,
    },
    '&:hover:active': {
      backgroundColor: '#0574D4',
    },
    '&:active': {
      backgroundColor: '#0574D4',
    },
  },
  currentPlan: {
    backgroundColor: '#0574D4',
    color: '#FFFFFF',
    boxShadow: '0 2px 1px 0 #013969 !important',
    border: `1px solid ${props.theme.colors.premierBlue} !important`,
    outline: 'none',

    '&:hover': {
      background: `${props.theme.colors.premierBlue} !important`,
      color: '#FFFFFF !important',
      cursor: 'default',
    },
  },
  featuresList: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '10px',

    '> div': {
      flex: 1,
      margin: 0,

      '> li': {
        listStyle: 'disc',
        marginLeft: '18px',
        paddingBottom: '6px',
        textAlign: 'left',
      },
    },
    [props.theme.breakpoints.tablet]: {
      flexDirection: 'row',
      marginTop: 0,

      '> div': {
        '&:first-of-type': {
          marginRight: '20px',
        },
      },
    },
  },
  paymentMethodContainer: {
    maxWidth: '1068px',
    margin: '50px auto 0',
    padding: '28px',
    [props.theme.breakpoints.tablet]: {
      padding: '28px 0 0 10px',
    },
    borderTop: props.theme.globalDashedBorder,
    '>h1': {
      fontWeight: '700',
      color: props.theme.colors.orange,
    },
  },
  approvalContainer: {
    textAlign: 'center',
  },
  deletingCard: {
    padding: '10px',
    marginTop: '10px',
    border: `1px solid ${props.theme.colors.blue}`,
    color: props.theme.colors.blue,
    borderRadius: '2px',
    display: 'inline-block',
  },
  cardContainer: {
    display: 'flex',
    gap: '1rem',
    marginTop: '1rem',
    flexDirection: 'column',
    [props.theme.breakpoints.tablet]: {
      flexDirection: 'row',
    },
  },
  card: {
    margin: '14px 0',
    borderRadius: '4px',
  },
  remove: {
    color: props.theme.colors.darkerRed,
    textDecoration: 'underline',
  },
  blueLink: {
    color: props.theme.colors.blue,
  },
  // paymentCard: {
  //   background: 'white',
  //   borderRadius: '12px',
  //   padding: '1rem',
  //   boxShadow: props.theme.globalBoxShadow,
  // },
});

export default styles;
