import React, { useState } from 'react';

import { Button, Icon, PlainLink, Modal, ModalHeader, ModalBody, ModalFooter } from '../../../../../common/components';
import formatPrice from '../../../../../common/utils/formatPrice';

const UpgradeToPremierCard = ({ styles, onSelectUpgrade, price, supportContactRequired = false }) => {
  const [showFeaturesModal, setShowFeaturesModal] = useState(false);

  return (
    <>
      <div className={styles.membershipCardWrapper}>
        <div className={styles.upgradeCard}>
          <div className={styles.upgradeCardleft}>
            <div className={styles.upgradeCardTitle}>
              <Icon icon="PremierBadgeWithBackground" size={32} />
              <h4>
                Need more features? Upgrade to <strong>Premier!</strong>
              </h4>
              <div className={styles.whiteSpacer} />
            </div>
            <p>
              Premier members get 40 listings, complimentary ID verification, enhanced listings and more.{' '}
              <PlainLink
                to={'#'}
                onClick={() => {
                  setShowFeaturesModal(true);
                }}
              >
                See all premier features
              </PlainLink>
            </p>
          </div>
          <div className={styles.upgradeCardRight}>
            <div className={styles.upgradeCardRightContent}>
              <div className={styles.upgradeCardPrice}>{`${formatPrice(price)}/monthly`}</div>
              {supportContactRequired ? (
                <PlainLink
                  to={'#'}
                  onClick={() =>
                    window.open(encodeURI('mailto:support@puppies.com?subject=Premier Membership upgrade request'))
                  }
                >
                  Contact support
                </PlainLink>
              ) : (
                <Button type="button" buttonType="round" onClick={() => onSelectUpgrade()}>
                  Upgrade Now
                </Button>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal closeOnEscape closeOnOutsideClick isOpen={showFeaturesModal} onClose={() => setShowFeaturesModal(false)}>
        <ModalHeader title={'Premier Features'} subTitle={'All paid plan features, plus:'} variant="premierBlue" />
        <ModalBody>
          <ul className={styles.featuresList}>
            <div>
              <li>Create up to 40 listings at a time</li>
              <li>Search engine optimized premier profile with custom Puppies.com URL</li>
              <li>Public contact info on profile</li>
            </div>
            <div>
              <li>Featured listings - stand out with a badge</li>
              <li>Breeder news updates on profile - keep viewers informed</li>
            </div>
          </ul>
        </ModalBody>
        <ModalFooter
          noBorder
          actions={[
            <Button type="button" buttonType="round" onClick={() => setShowFeaturesModal(false)}>
              Close
            </Button>,
          ]}
        />
      </Modal>
    </>
  );
};

export default UpgradeToPremierCard;
